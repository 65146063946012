import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'

import { SEOComponent } from '@/common/components/seo/seo'
import { HOME_SECTION, HOME_SLUG } from '@/common/constants/gtmPageConstants'
import { HomeContainer } from '@/components/containers/home'
import PreviewPage from '@/components/containers/previews/pagePreview'
import { env } from '@/env'
import { getClient } from '@/sanity/client'
import { getHomePageBase, homePage } from '@/sanity/queries/page/homePage'
import {
  getQueryBasedOnStructure,
  mergeStructure,
} from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import { getCommonData } from '@/sanity/services/common/getCommonData'
import { getHomePage, getHomePageStructure } from '@/sanity/services/page/getHomePage'

type HomeProps = InferGetStaticPropsType<typeof getStaticProps>

const Home = (props: HomeProps) => {
  const { data, draftMode, token, layoutProps } = props

  if (draftMode && token) {
    return (
      <PreviewPage
        layoutProps={layoutProps}
        data={data}
        query={homePage}
        seo={data.seo}
        Container={HomeContainer}
        token={token}
      />
    )
  }

  return (
    <>
      <SEOComponent data={data.seo} />
      <HomeContainer data={data} />
    </>
  )
}

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const { draftMode = false, revalidateReason } = ctx
  const queryParams = { slug: HOME_SLUG }
  const draftViewToken = draftMode ? env.SANITY_API_READ_TOKEN : ''
  const { client } = getClient({
    draftMode,
    draftViewToken,
    revalidateReason,
    slug: HOME_SLUG,
    originName: 'home-getStaticProps',
    filePath: __filename,
  })

  const dataStructure = await getHomePageStructure(client)
  const structureMerged = mergeStructure(dataStructure)
  const q = getQueryBasedOnStructure({ structure: structureMerged })
  const improvedQ = getHomePageBase(q)

  const [{ layoutProps, dataLayerProps }, data] = await Promise.all([
    getCommonData(client, queryParams),
    getHomePage(client, improvedQ),
  ])

  if (!data) return { notFound: true }
  if (dataLayerProps) dataLayerProps.page_data.site_section = HOME_SECTION

  return {
    props: {
      data,
      dataLayerProps,
      draftMode,
      token: draftViewToken,
      layoutProps,
    },
    revalidate: env.GLOBAL_REVALIDATE_VALUE,
  }
}

export default Home
