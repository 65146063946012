import { MEDIA_ASPECT_RATIOS } from '@zwirner/design-system'
import Image from 'next/image'

import { LocationAddressData } from '@/common/components/locations/LocationAddressData'
import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'

export const mapTabsLocations = (data: any) => {
  const mappedLocationsByCity =
    data?.reduce((prev: any, loc: any) => {
      const { address, name } = loc ?? {}

      const { city } = address ?? {}

      const { media } = dzMediaMapper({
        data: loc,
        ImgElement: Image,
        options: {
          aspectRatio: MEDIA_ASPECT_RATIOS['16:9'],
        },
        extraImgProps: {
          itemProp: 'image',
          property: 'image',
        },
      })

      const cardLocation = {
        media,
        title: name,
        secondaryTitle: <LocationAddressData loc={loc} />,
        secondarySubtitle: '',
        property: 'address',
        typeof: 'PostalAddress',
        itemProp: 'address',
        itemScope: '',
        itemType: 'https://schema.org/PostalAddress',
      }

      if (prev[city] && prev[city].cards && Array.isArray(prev[city].cards)) {
        const cardsCopy = [...prev[city].cards]
        cardsCopy.push(cardLocation)
        prev[city].cards = cardsCopy
        return prev
      }
      prev[city] = { title: city, cards: [cardLocation] }
      return prev
    }, {}) ?? {}

  return Object.entries(mappedLocationsByCity).map((entry) => {
    const [_, locationGroup] = entry
    return locationGroup
  })
}
