import { DzTabsCards as DzTabsCardsBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzTabsCardsProps = ComponentProps<typeof DzTabsCardsBasic>
type Props = Omit<DzTabsCardsProps, 'LinkElement'>

export const DzTabsCards = (props: Props) => {
  const dzTabsCardsProps = { ...props, LinkElement: Link } as DzTabsCardsProps
  return <DzTabsCardsBasic {...dzTabsCardsProps} />
}

DzTabsCards.displayName = 'DzTabsCardsWrapper'
